<template>
  <div class="uk-width-1-1">
    <div class="uk-background-default uk-padding-small">
      <div class="uk-form-stacked">
        <div class="uk-margin">
          <label class="uk-form-label">Bild</label>
          <select-image :source="form.src" @image-selected="onImageSelected" @image-removed="onImageRemoved" />
          <input type="text" class="uk-input" v-model="form.src" placeholder="http://">
        </div>
        <div class="uk-margin">
          <div class="uk-grid uk-child-width-1-2@m">
            <div>
              <div>
               <label class="uk-form-label">Breite</label>
               <input type="text" class="uk-input" v-model="form.width" placeholder="auto" />
            </div>
            </div>
            <div>
               <label class="uk-form-label">Höhe</label>
               <input type="text" class="uk-input" v-model="form.height" placeholder="auto" />
            </div>
          </div>
          <p class="uk-text-small uk-text-muted uk-margin-small">Erklärungstext hier rein!</p>
        </div>
        <div class="uk-margin">
         <label class="uk-form-label">Alt Text</label>
         <input type="text" class="uk-input" v-model="form.alt" />
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Link</label>
          <div class="uk-form-controls">
            <input type="text" class="uk-input" v-model="form.link">
            <p class="uk-text-small uk-text-muted uk-margin-small">Link einfügen</p>
            <label>
              <input
                type="checkbox"
                class="uk-checkbox"
                v-model="form.target"
                :true-value="'_blank'"
                :false-value="'_self'"
                :disabled="!form.link"
              > Link in einem neuen Fenster öffnen
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectImage from '@/components/finder/SelectImage'
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  components: {
    SelectImage
  },
  data() {
    return {
      form: {
        content: "",
        link: "",
        target: ""
      }
    };
  },
  mounted() {
    this.form = this.data;
  },
  methods: {
    onImageSelected(image) {
      this.form.src = image;
    },
    onImageRemoved() {
      this.form.src = ''
    }
  }
};
</script>
