<template>
  <div>
    <a class="uk-placeholder uk-text-center uk-display-block uk-margin-bottom uk-link-reset" v-if="!source" @click.prevent="pick">
        <img width="60" height="60" alt="Placeholder Image" src="/img/placeholder-image.svg">
        <p class="uk-text-muted uk-margin-small-top">Bild auswählen</p>
    </a>

    <div class="uk-inline uk-margin-bottom" v-else>
      <div class="uk-overlay-hover uk-visible-toggle">
          <img :src="source">

          <div class="uk-overlay-panel uk-overlay-background uk-overlay-fade"></div>
          <a class="uk-position-cover" @click="pick"></a>
          <div class="uk-position-top-right uk-invisible-hover" style="padding: 5px;">
              <a
                href="#"
                class="uk-text-danger uk-icon-button"
                uk-icon="icon: trash; ratio:1.2"
                @click.prevent="remove"
              ></a>
          </div>
      </div>
    </div>
    <modal ref="modal" large>

      <div class="uk-modal-body uk-background-muted">
        <finder id="finder" ref="finder" mode="write"></finder> 
      </div>
      <div class="uk-modal-footer uk-text-right">
        <button class="uk-button uk-button-link uk-modal-close uk-margin-small-right" type="button">Abbrechen</button>
        <button class="uk-button uk-button-primary" type="button" :disabled="!hasSelection" @click.prevent="select">Übernehmen</button>
      </div>

    </modal>
  </div>
</template>
<script>
import Modal from '@/components/Modal'
import Finder from '@/components/finder/Index'
export default {
  props: {
    source: {default: ''}
  },
  components: {
    Finder,
    Modal
  },
  data() {
    return {
      localSource: '',
      finder: null
    }
  },
  mounted() {
    this.localSource = this.source
  },
  computed: {
    hasSelection() {
      if (this.finder) {
      let selected = this.finder.getSelected();
        return selected.length === 1 && this.finder.isImage(selected[0])
      }
      return false
    }
  },
  methods: {
    pick() {
      this.$refs.modal.open();
      setTimeout(() => {
        this.finder = this.$refs.finder
      }, 500)
      
    },
    select() {
      this.source = this.$refs.finder.getSelected()[0];
      this.$emit('image-selected', this.source);
      this.$refs.finder.removeSelection();
      this.$refs.modal.close();
    },
    remove() {
      this.source = ''
       this.$emit('image-removed');
    }
  }
}
</script>